import { Layout } from '@components/layout';
import { PageHeader } from '@components/shared';
import { UsesPosts } from '@components/standard';
import { IPage, IPostPage, IPost, ILocation } from '@standardTypes';
import React from 'react';

interface IPageContext {
  pageContext: {
    page: IPage | IPostPage | IPost;
    posts: IPost[];
  };
  location: ILocation;
}

const descPlaceholder = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at
venenatis egestas amet. Vitae aliquam blandit arcu, amet urna,
tincidunt. Pellentesque velit eget diam adipiscing neque, elit arcu.`;

const UsesPage: React.FC<IPageContext> = ({
  pageContext,
  location
}): JSX.Element => {
  const { page, posts } = pageContext;
  const { search } = location;
  return (
    <Layout {...page}>
      <PageHeader title="Zastosowania" description={descPlaceholder} />
      <UsesPosts posts={posts} pageParam={search} />
    </Layout>
  );
};

export default UsesPage;
